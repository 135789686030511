import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AnyObject } from "yup/lib/types";
import {
  BackendError,
  Comarca,
  DatosSolicitud,
  ProfesionalAdjuntosV1,
} from "../models";

export interface ProfesionalFormulario {
  estado: string;
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  dni: string;
  codigo: string;
  datosSolicitud: DatosSolicitud;
  datosAdjuntos: ProfesionalAdjuntosV1;
  version: number;
  tipo: string;
  comarcaId: string;
  comarca?: Comarca;
  notas: string;
}

@Module({ namespaced: true, name: "ProfesionalesFormulariosModule" })
export class ProfesionalesFormulariosModule extends VuexModule {
  errors: BackendError | null = null;
  profesionalesFormularios: ProfesionalFormulario[] = [];
  profesionalFormulario: ProfesionalFormulario | null = null;

  @Action
  fetchFormularios(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/peticion-formularios/profesionales"
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: ["comarca"],
          where: {
            estado: { neq: "INCORPORADO" },
          },
        },
      },
    })
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/profesionales/${id}`
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: ["comarca"],
        },
      },
    })
      .then(({ data }) => {
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action({ rawError: true })
  update({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url = `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}`;
    return ApiService.put(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  aprobar({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/profesionales/${id}/aprobar`
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}/aprobar`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  noApto({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/profesionales/${id}/noApto`
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}/noApto`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  realizadoPorError({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/profesionales/${id}/realizadoPorError`
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}/realizadoPorError`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  pendiente({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/profesionales/${id}/pendiente`
        : `/comarcas/${comarcaId}/peticion-formularios/profesionales/${id}/pendiente`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  cambiarComarca({ id, comarcaId }: { id: string; comarcaId: string }) {
    const url = `/peticion-formularios/${id}/cambiarComarca`;
    return ApiService.put(url, { comarcaId })
      .then(() => {
        this.setComarcaId(comarcaId);
      })
      .catch(ApiService.handleError);
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.profesionalesFormularios = data;
  }

  @Mutation
  setDetailData(data) {
    this.profesionalFormulario = data;
  }

  @Mutation
  setComarcaId(comarcaId: string) {
    if (this.profesionalFormulario) {
      this.profesionalFormulario.comarcaId = comarcaId;
    }
  }
}
