import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, Municipio } from "../models";

@Module({ namespaced: true, name: "MunicipiosModule" })
export class MunicipiosModule extends VuexModule {
  municipios: Municipio[] = [];
  errors: BackendError | null = null;

  @Action({ rawError: true })
  async fetch() {
    const url = "/municipios";

    return ApiService.get(url)
      .then(({ data }) => {
        this.setListData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Mutation
  setListData(data) {
    this.municipios = data;
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }
}
