import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { AuthModule } from "@/store/modules";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getModule } from "vuex-module-decorators";

const formularioBaseUrl = process.env.VUE_APP_FORMULARIOS_URL;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/solicitudes/servicios",
        name: "Servicios",
        component: () => import("@/views/solicitudes/Servicios.vue"),
      },
      {
        path: "/solicitudes/familias",
        name: "Familias",
        component: () => import("@/views/solicitudes/Familias.vue"),
      },
      {
        path: "/solicitudes/familias/:id",
        component: () => import("@/views/solicitudes/FamiliaDetalle.vue"),
      },
      {
        path: "/solicitudes/profesionales",
        name: "Profesionales",
        component: () => import("@/views/solicitudes/Profesionales.vue"),
      },
      {
        path: "/solicitudes/profesionales/:id",
        component: () => import("@/views/solicitudes/ProfesionalDetalle.vue"),
      },
      {
        path: "/solicitudes/profesionales-modificacion",
        name: "profesionales-modificacion",
        component: () =>
          import("@/views/solicitudes/ProfesionalesModificacion.vue"),
      },
      {
        path: "/solicitudes/profesionales-modificacion/:id",
        component: () =>
          import("@/views/solicitudes/ProfesionalModificacionDetalle.vue"),
      },
      {
        path: "/listados/familias",
        component: () => import("@/views/listados/FamiliasListado.vue"),
      },
      {
        path: "/listados/familias/:id",
        component: () => import("@/views/listados/FamiliaDetalle.vue"),
      },
      {
        path: "/listados/familia",
        component: () => import("@/views/listados/FamiliaNueva.vue"),
      },
      {
        path: "/listados/menores",
        component: () => import("@/views/menores/MenoresListado.vue"),
      },
      {
        path: "/listados/menores/:id",
        component: () => import("@/views/menores/MenorDetalle.vue"),
      },
      {
        path: "/listados/serviciosprestados",
        component: () =>
          import("@/views/listados/ServiciosPrestadosListado.vue"),
      },
      {
        path: "/listados/serviciossolicitados",
        component: () =>
          import("@/views/listados/ServiciosSolicitadosListado.vue"),
      },
      {
        path: "/listados/servicios-programados",
        component: () =>
          import(
            "@/views/serviciosProgramados/ServiciosProgramadosListado.vue"
          ),
      },
      {
        path: "/listados/servicios-programados-solicitudes",
        component: () =>
          import(
            "@/views/serviciosProgramados/ServiciosProgramadosListadoSolicitudes.vue"
          ),
      },
      {
        path: "/listados/servicios-programados-solicitudes/:id",
        component: () =>
          import(
            "@/views/serviciosProgramados/ServiciosProgramadosListadoSolicitudesDetalle.vue"
          ),
      },
      {
        path: "/listados/servicios-programados/nuevo",
        component: () =>
          import(
            "@/views/serviciosProgramados/ServiciosProgramadosDetalle.vue"
          ),
      },
      {
        path: "/listados/servicios-programados/:id",
        component: () =>
          import(
            "@/views/serviciosProgramados/ServiciosProgramadosDetalle.vue"
          ),
      },
      {
        path: "/listados/servicios/:id",
        component: () => import("@/views/listados/ServicioDetalle.vue"),
      },
      {
        path: "/listados/profesionales/:id",
        component: () => import("@/views/profesionales/ProfesionalDetalle.vue"),
      },
      {
        path: "/listados/profesionales",
        component: () =>
          import("@/views/profesionales/ProfesionalesListado.vue"),
      },
      {
        path: "/listados/profesionalesCA",
        component: () =>
          import("@/views/profesionales/ProfesionalesListadoCA.vue"),
      },
      {
        path: "/listados/profesionalesCA/:id",
        component: () =>
          import("@/views/profesionales/ProfesionalesDetalleCA.vue"),
      },
      {
        path: "/listados/personal",
        component: () => import("@/views/personal/PersonalListado.vue"),
      },
      {
        path: "/listados/personal/:id",
        component: () => import("@/views/personal/PersonalDetalle.vue"),
      },
      {
        path: "/listados/personal/nuevo",
        component: () => import("@/views/personal/PersonalDetalle.vue"),
      },
      {
        path: "/listados/organismos",
        component: () => import("@/views/organismos/OrganismosListado.vue"),
      },
      {
        path: "/listados/organismos/:id",
        component: () => import("@/views/organismos/OrganismosDetalle.vue"),
      },
      {
        path: "/listados/organismos/nuevo",
        component: () => import("@/views/organismos/OrganismosDetalle.vue"),
      },
      {
        path: "/listados/proyectosN2",
        component: () => import("@/views/proyectosN2/ProyectosN2Listado.vue"),
      },
      {
        path: "/listados/datosProyectoN2",
        component: () => import("@/views/proyectosN2/RecopilarDatosPN2.vue"),
      },
      {
        path: "/listados/proyectosN2/:id",
        component: () => import("@/views/proyectosN2/ProyectosN2Detalle.vue"),
      },
      {
        path: "/listados/proyectosN2/nuevo",
        component: () => import("@/views/proyectosN2/ProyectosN2Detalle.vue"),
      },
      {
        path: "/listados/datosProyectoN2/:id",
        component: () =>
          import("@/views/proyectosN2/RecopilarDatosPN2Detalle.vue"),
      },
      {
        path: "/listados/datosProyectoN2/nuevo",
        component: () =>
          import("@/views/proyectosN2/RecopilarDatosPN2Detalle.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/reset-password/:token",
        name: "password-confirmation-token",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordConfirmation.vue"
          ),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/encuesta",
    name: "encuesta",
    component: () => import("@/views/crafted/Loader.vue"),
    beforeEnter: function (me) {
      location.href = formularioBaseUrl + "#" + me.fullPath;
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
const authModule = getModule(AuthModule, store);

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  authModule.verifyAuth();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
