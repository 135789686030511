import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AnyObject } from "yup/lib/types";
import {
  BackendError,
  Comarca,
  DatosSolicitudFamilia,
  FamiliaAdjuntosV1,
  FamiliaListado,
  Menor,
  PeticionFormulario,
  ServicioProgramado,
} from "../models";

export interface FamiliaFormulario {
  familiaId: string;
  peticionFormularioId: string;
  peticionFormulario: PeticionFormulario;
  familia: FamiliaListado;
  estado: string;
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  dni: string;
  codigo: string;
  datosSolicitud: DatosSolicitudFamilia;
  datosAdjuntos: FamiliaAdjuntosV1;
  version: number;
  tipo: string;
  comarcaId: string;
  comarca?: Comarca;
  notas: string;
  servicioProgramado?: ServicioProgramado;
  menor?: Menor;
}

@Module({ namespaced: true, name: "FamiliasFormulariosModule" })
export class FamiliasFormulariosModule extends VuexModule {
  errors: BackendError | null = null;
  familiasFormularios: FamiliaFormulario[] = [];
  familiaFormulario: FamiliaFormulario | null = null;

  @Action
  fetchFormularios(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/peticion-formularios/familias"
        : `/comarcas/${comarcaId}/peticion-formularios/familias`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            { relation: "comarca" },
            {
              relation: "familia",
              scope: {
                fields: ["id", "nombre", "apellidos", "dni", "telefono"],
              },
            },
          ],
          where: {
            estado: { neq: "INCORPORADO" },
          },
        },
      },
    })
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}`;

    return ApiService.get(url, {
      params: { filter: { include: ["comarca"] } },
    })
      .then(({ data }) => {
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  aprobar({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}/aprobar`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}/aprobar`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  noApto({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}/noApto`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}/noApto`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  realizadoPorError({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}/realizadoPorError`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}/realizadoPorError`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  pendiente({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}/pendiente`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}/pendiente`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  cambiarComarca({ id, comarcaId }: { id: string; comarcaId: string }) {
    const url = `/peticion-formularios/${id}/cambiarComarca`;
    return ApiService.put(url, { comarcaId })
      .then(() => {
        this.setComarcaId(comarcaId);
      })
      .catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  update({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url = `/comarcas/${comarcaId}/peticion-formularios/familias/${id}`;
    return ApiService.put(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
      throw this.errors;
    });
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.familiasFormularios = data;
  }

  @Mutation
  setDetailData(data) {
    this.familiaFormulario = data;
  }

  @Mutation
  setComarcaId(comarcaId: string) {
    if (this.familiaFormulario) {
      this.familiaFormulario.comarcaId = comarcaId;
    }
  }
}
