const tratamientos = [
  { id: "DON", text: "D" },
  { id: "DOÑA", text: "Dª" },
  { id: "OTRO", text: "Otro" },
  { id: "NO_CONTESTA", text: "Prefiero no contestar" },
];

export const tratamientoMap = tratamientos.reduce((prev, value) => {
  return {
    ...prev,
    [value.id]: value.text,
  };
}, {});

export default tratamientos;
