import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import {
  AuthModule,
  FamiliasFormulariosModule,
  FamiliasListadoModule,
  FormulariosModule,
  MenorModule,
  MunicipiosModule,
  OrganismosModule,
  ProfesionalesFormulariosModule,
  ProfesionalesListadoModule,
  ProyectosN2ListadoModule,
  ServicioListadoModule,
  ServiciosProgramadosModule,
  UsuariosListadoModule,
} from "@/store/modules";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    FamiliasFormulariosModule,
    FamiliasListadoModule,
    MenorModule,
    MunicipiosModule,
    ProfesionalesFormulariosModule,
    ProfesionalesListadoModule,
    ServicioListadoModule,
    ServiciosProgramadosModule,
    UsuariosListadoModule,
    OrganismosModule,
    ProyectosN2ListadoModule,
    FormulariosModule,
  },
});

export default store;
