import ApiService from "@/core/services/ApiService";
import { Documento, Experiencia, IAMFile, Idioma, Tiempo } from "@/types";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AnyObject } from "yup/lib/types";
import { ServicioListado } from ".";
import { BackendError, Comarca, Evento } from "../models";

export interface ProfesionalListado {
  estado: string;
  inicio: Date;
  fin: Date;
  profesional: ProfesionalListado;
  createdOn: Date;
  modifiedOn: Date;
  id: string;

  /*
  titulacion: true;
  idiomas: true;
  experiencia: true;
  */

  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  fechaNacimiento: Date;
  direccion: string;
  provincia: string;
  localidad: string;
  carnetConducir: boolean;
  vehiculo?: string;
  disponeTitulacion: boolean;
  experienciaCuidadoMenores: string;
  laborables: boolean;
  finesDeSemana: boolean;
  festivos: boolean;
  urgencias: boolean;
  noches: boolean;
  detalleDisponibilidad?: string;
  comarcaId: string;
  direccionComarcaId: string;
  comarca?: Comarca;
  comarcasTrabajo?: Comarca[];
  comarcasSolicitaTrabajo?: Comarca[];
  idiomas?: Idioma[];
  documentos?: Documento[];
  disponibilidadesArray?: Tiempo[];
  experiencia?: Experiencia[];
  titulacion?: string[];
  comarcasTrabajoIds?: string[];
  comarcasSolicitaTrabajoIds?: string[];
  titulacionOtras?: string;
  documentosEliminar?: string[];
  documentosNuevos?: IAMFile[];
  servicios?: ServicioListado[];
}
export interface DatosAdjuntos {
  datosAdjuntos?: IAMFile[];
}

export interface DatosProfesionalModificarDatos {
  dni?: string;
  telefono?: string;
  detalleActualizacion?: string;
}

export interface PeticionProfesionalModificarDatos {
  datosSolicitud: DatosProfesionalModificarDatos;
  datosAdjuntos?: DatosAdjuntos;
  id: string;
}

@Module({ namespaced: true, name: "ProfesionalesListadoModule" })
export class ProfesionalesListadoModule extends VuexModule {
  errors: BackendError | null = null;
  // errors = {};
  profesionalesListado: ProfesionalListado[] = [];
  profesional: ProfesionalListado | null = null;
  profesionalActualizarDatos: PeticionProfesionalModificarDatos | null = null;
  eventosListado: Evento[] = [];

  @Action
  fetchProfesionales(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/profesionales"
        : `/comarcas/${comarcaId}/profesionales`;

    const include =
      comarcaId === null
        ? { params: { filter: { include: ["comarca"] } } }
        : {};

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchProfesionalesSolicitudModificacion(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/profesionalesActualizarDatos"
        : `/comarcas/${comarcaId}/profesionalesActualizarDatos`;

    const include =
      comarcaId === null
        ? { params: { filter: { include: ["comarca"] } } }
        : {};

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchProfesionalesCA(comarcaId?: string) {
    const url = `/profesionalesCA/${comarcaId}`;

    const include = {
      params: {
        filter: {
          fields: [
            "id",
            "telefono",
            "email",
            "dni",
            "comarcaId",
            "nombre",
            "apellidos",
            "localidad",
            "carnetConducir",
            "vehiculo",
          ],
          include: [
            {
              relation: "comarca",
              scope: { fields: ["id", "nombre"] },
            },
          ],
        },
      },
    };

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchProfesionalActualizarDatosById({
    comarcaId,
    id,
  }: {
    comarcaId: string;
    id: string;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}/actualizarDatos`
        : `/comarcas/${comarcaId}/profesionales/${id}/actualizarDatos`;

    return ApiService.get(url)
      .then(({ data }) => {
        this.setDataActualizarDatos(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}`
        : `/comarcas/${comarcaId}/profesionales/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            { relation: "comarca" },
            { relation: "comarcasTrabajo" },
            { relation: "documentos" },
            {
              relation: "servicios",
              scope: {
                include: [
                  { relation: "familia" },
                  { relation: "servicioProgramado" },
                  { relation: "menores" },
                ],
              },
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        data.comarcasTrabajoIds = data.comarcasTrabajo.map(
          (comarca) => comarca.id
        );
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchProfesionalCAById({ id }: { id: string }) {
    const url = `/profesionalCA/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            { relation: "comarca" },
            { relation: "comarcasTrabajo" },
            { relation: "documentos" },
            {
              relation: "servicios",
              scope: {
                include: [
                  { relation: "familia" },
                  { relation: "servicioProgramado" },
                  { relation: "menores" },
                ],
              },
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        data.comarcasTrabajoIds = data.comarcasTrabajo.map(
          (comarca) => comarca.id
        );
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchProfesionalHistorico({
    comarcaId,
    id,
  }: {
    comarcaId: string;
    id: string;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}/registros-historicos`
        : `/comarcas/${comarcaId}/profesionales/${id}/registros-historicos`;

    return ApiService.get(url)
      .then(({ data }) => {
        this.setEventosData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  profesionalHistorico({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: Partial<Evento>;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}/registros-historicos`
        : `/comarcas/${comarcaId}/profesionales/${id}/registros-historicos`;
    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  update({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}`
        : `/comarcas/${comarcaId}/profesionales/${id}`;
    return ApiService.put(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  aceptarDatosProfesional({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}/aceptarDatosProfesional`
        : `/comarcas/${comarcaId}/profesionales/${id}/aceptarDatosProfesional`;

    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  rechazarDatosProfesional({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/profesionales/${id}/rechazarDatosProfesional`
        : `/comarcas/${comarcaId}/profesionales/${id}/rechazarDatosProfesional`;

    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  eliminar({ comarcaId, elementId }: { comarcaId: string; elementId: string }) {
    const url = `/comarcas/${comarcaId}/profesionales/${elementId}`;

    return ApiService.delete(url).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  incluirProfesional({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url = `/comarcas/${comarcaId}/profesionales/${id}/incluir`;

    return ApiService.post(url, {}).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.profesionalesListado = data;
  }

  @Mutation
  setDetailData(data) {
    this.profesional = data;
  }
  @Mutation
  setDataActualizarDatos(data) {
    this.profesionalActualizarDatos = data;
  }
  @Mutation
  setEventosData(data) {
    this.eventosListado = data;
  }
}
