import ApiService from "@/core/services/ApiService";
import { ServicioEstado } from "@/enums";
import { ProfesionalEstado } from "@/enums/profesional-estado.enum";
import { Experiencia, Idioma } from "@/types";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AnyObject } from "yup/lib/types";
import {
  BackendError,
  Comarca,
  DatosSolicitudFamilia,
  Disponibilidad,
  FamiliaListado,
  ServicioProgramado,
} from "../models";
import { ProfesionalListado } from "./ProfesionalesListadoModule";

export interface ServicioProfesionalListado {
  profesional: ProfesionalListado[];
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  fechaNacimiento: Date;
  direccion: string;
  provincia: string;
  localidad: string;
  carnetConducir: boolean;
  vehiculo: string;
  disponeTitulacion: boolean;
  titulacion: Array<string>;
  titulacionOtras: string;
  idiomas: Idioma[];
  experiencia: Experiencia[];
  experienciaCuidadoMenores: string;
  laborables: boolean;
  finesDeSemana: boolean;
  festivos: boolean;
  urgencias: boolean;
  noches: boolean;
  detalleDisponibilidad: string;
  disponibilidadesArray: Disponibilidad[];
  estado: ProfesionalEstado;
  direccionComarcaId: string;
  disponible: boolean;
}
export interface ServicioListado {
  fecha: Date;
  servicioProgramado?: ServicioProgramado;
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
  lugarPrestacion: string;
  lugarEspacioColectivo: string;
  otrosEspacioColectivo: string;
  direccion: string;
  descripcion: string;
  familiaId: string;
  familia?: FamiliaListado;
  comarca?: Comarca;
  comarcaId: string;
  inicioPrevisto: Date;
  finPrevisto: Date;
  tiempos: Disponibilidad[];
  estado: ServicioEstado;
  fechas: Array<string>;
  profesionalesAsignados?: ProfesionalAsignado[];
  menores: Menor[];
  servicioProgramadoId?: string;
  proyectoId?: string;
}

export interface PeticionFormulario {
  datosSolicitud: DatosSolicitudFamilia;
  id: string;
}

export interface ServicioSolicitudListado {
  datosSolicitud: DatosSolicitudFamilia;
  dni: string;
  peticionFormulario: PeticionFormulario;
  fecha: Date;
  servicioProgramado?: ServicioProgramado;
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
  lugarPrestacion: string;
  lugarEspacioColectivo: string;
  otrosEspacioColectivo: string;
  direccion: string;
  descripcion: string;
  familiaId: string;
  familia?: FamiliaListado;
  comarca?: Comarca;
  comarcaId: string;
  inicioPrevisto: Date;
  finPrevisto: Date;
  tiempos: Disponibilidad[];
  estado: ServicioEstado;
  fechas: Array<string>;
  profesionalesAsignados?: ProfesionalAsignado[];
  menores: Menor[];
  servicioProgramadoId?: string;
  proyectoId?: string;
}

export interface ProfesionalAsignado {
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  fechaInicio: Date;
  fechaFin: Date;
  servicioId: string;
  profesionalId: string;
  profesional: ProfesionalListado;
}

export interface Menor {
  id: string;
  tratamiento: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
}

@Module({ namespaced: true, name: "ServicioListadoModule" })
export class ServicioListadoModule extends VuexModule {
  errors: BackendError | null = null;
  // errors = {};
  serviciosListado: ServicioListado[] = [];
  profesionales: ServicioProfesionalListado[] = [];
  servicio: ServicioListado | null = null;

  @Action
  fetchServiciosPrestados(comarcaId?: string) {
    const url =
      comarcaId == null ? "/servicios" : `/comarcas/${comarcaId}/servicios`;

    const filter = {
      params: {
        filter: {
          order: "modified_on DESC",
          include: [
            {
              relation: "familia",
              scope: {
                fields: [
                  "id",
                  "dni",
                  "nombre",
                  "apellidos",
                  "familiaPrioritaria",
                  "conIngresos",
                  "ingresos",
                  "localidad",
                ],
              },
            },
            {
              relation: "menores",
              scope: {
                fields: ["id", "nombre", "apellidos"],
              },
            },
          ],
          where: {
            estado: ServicioEstado.aprobado,
            servicioProgramadoId: null,
          },
        },
      },
    };

    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServiciosSolicitados(comarcaId?: string) {
    const url =
      comarcaId == null ? "/servicios" : `/comarcas/${comarcaId}/servicios`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "familia",
              scope: {
                fields: [
                  "id",
                  "dni",
                  "telefono",
                  "nombre",
                  "apellidos",
                  "familiaPrioritaria",
                  "conIngresos",
                  "ingresos",
                  "localidad",
                ],
              },
            },
            {
              relation: "menores",
              scope: {
                fields: ["id", "nombre", "apellidos"],
              },
            },
          ],
          where: {
            estado: { neq: ServicioEstado.aprobado },
            servicioProgramadoId: null,
          },
        },
      },
    };

    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        // Ordenamos por familia prioritaria y luego por ingresos (de menos a más) Trello#24
        const estadoOrder = {
          [ServicioEstado.aprobado]: 1,
          [ServicioEstado.pendiente]: 2,
          [ServicioEstado.rechazado]: 3,
          [ServicioEstado.realizadorPorError]: 3,
        };
        const dataSorted = data.sort((a, b) => {
          if (estadoOrder[a.estado] !== estadoOrder[b.estado]) {
            return estadoOrder[a.estado] - estadoOrder[b.estado];
          }

          if (!a.familia || !b.familia) return 0;

          if (a.familia.familiaPrioritaria === b.familia.familiaPrioritaria)
            return (
              (parseInt(a.familia.ingresos) || 0) -
              (parseInt(b.familia.ingresos) || 0)
            );

          return b.familia.familiaPrioritaria - a.familia.familiaPrioritaria;
        });
        this.setData(dataSorted);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }
  @Action
  fetchServiciosSolicitadosProyecto({
    comarcaId,
    proyectoId,
  }: {
    comarcaId: string;
    proyectoId: string;
  }) {
    console.log(comarcaId, proyectoId);
    const url =
      comarcaId == null ? "/servicios" : `/comarcas/${comarcaId}/servicios`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "familia",
              scope: {
                fields: [
                  "id",
                  "dni",
                  "telefono",
                  "nombre",
                  "apellidos",
                  "familiaPrioritaria",
                  "conIngresos",
                  "ingresos",
                  "localidad",
                ],
              },
            },
            {
              relation: "menores",
              scope: {
                fields: ["id", "nombre", "apellidos"],
              },
            },
            {
              relation: "comarca",
              scope: { fields: ["id", "nombre"] },
            },
          ],
          where: {
            //  estado: { neq: ServicioEstado.aprobado },
            servicioProgramadoId: null,
            proyectoId: proyectoId,
          },
        },
      },
    };

    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        // Ordenamos por familia prioritaria y luego por ingresos (de menos a más) Trello#24
        const estadoOrder = {
          [ServicioEstado.aprobado]: 1,
          [ServicioEstado.pendiente]: 2,
          [ServicioEstado.rechazado]: 3,
          [ServicioEstado.realizadorPorError]: 3,
        };
        const dataSorted = data.sort((a, b) => {
          if (estadoOrder[a.estado] !== estadoOrder[b.estado]) {
            return estadoOrder[a.estado] - estadoOrder[b.estado];
          }

          if (!a.familia || !b.familia) return 0;

          if (a.familia.familiaPrioritaria === b.familia.familiaPrioritaria)
            return (
              (parseInt(a.familia.ingresos) || 0) -
              (parseInt(b.familia.ingresos) || 0)
            );

          return b.familia.familiaPrioritaria - a.familia.familiaPrioritaria;
        });
        this.setData(dataSorted);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServicioProfesionales({
    comarcaId,
    id,
  }: {
    comarcaId: string;
    id: string;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${id}/profesionales`
        : `/comarcas/${comarcaId}/servicios/${id}/profesionales`;
    return ApiService.get(url, {
      params: {},
    })
      .then(({ data }) => {
        this.setProfesional(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/servicios/${id}`
        : `/comarcas/${comarcaId}/servicios/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            {
              relation: "profesionalesAsignados",
              scope: { include: [{ relation: "profesional" }] },
            },
            "familia",
            "menores",
          ],
        },
      },
    })
      .then(({ data }) => {
        if (data.servicios?.length) {
          data.servicios = data.servicios.map((servicio) => {
            return {
              ...servicio,
              familia: { dni: data.dni },
            };
          });
        }
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  update({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${id}`
        : `/comarcas/${comarcaId}/servicios/${id}`;

    return ApiService.put(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  asignarProfesional({
    comarcaId,
    idServicio,
    idProfesional,
    tipologiaContrato,
    tipologiaJornada,
  }: {
    comarcaId: string;
    idServicio: string;
    idProfesional: string;
    tipologiaContrato: string;
    tipologiaJornada: string;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${idServicio}/asignar`
        : `/comarcas/${comarcaId}/servicios/${idServicio}/asignar`;

    return ApiService.post(url, {
      profesionalId: idProfesional,
      tipologiaContrato,
      tipologiaJornada,
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  eliminarProfesional({
    comarcaId,
    servicioId,
    idProfesional,
  }: {
    comarcaId: string;
    servicioId: string;
    idProfesional: string;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${servicioId}/asignar`
        : `/comarcas/${comarcaId}/servicios/${servicioId}/desasignar`;

    return ApiService.post(url, { profesionalId: idProfesional }).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Action
  modificarFechasProfesional({
    comarcaId,
    idServicio,
    idProfesional,
    idProfesionalAsignado,
    fechaInicioServicio,
    fechaFinServicio,
  }: {
    comarcaId: string;
    idServicio: string;
    idProfesional: string;
    idProfesionalAsignado: string;
    fechaInicioServicio: Date;
    fechaFinServicio: Date;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${idServicio}/modificarFechas`
        : `/comarcas/${comarcaId}/servicios/${idServicio}/modificarFechas`;

    return ApiService.post(url, {
      profesionalId: idProfesional,
      id: idProfesionalAsignado,
      fechaInicio: fechaInicioServicio,
      fechaFin: fechaFinServicio,
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }
  @Action
  cancelarProfesional({
    comarcaId,
    idServicio,
    idProfesional,
    idProfesionalAsignado,
    fechaFinServicio,
  }: {
    comarcaId: string;
    idServicio: string;
    idProfesional: string;
    idProfesionalAsignado: string;
    fechaFinServicio: Date;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${idServicio}/cancelar`
        : `/comarcas/${comarcaId}/servicios/${idServicio}/cancelar`;

    return ApiService.post(url, {
      profesionalId: idProfesional,
      id: idProfesionalAsignado,
      fechaFin: fechaFinServicio,
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  asignarServicioColectivo({
    comarcaId,
    idServicio,
    idServicioColectivo,
  }: {
    comarcaId: string;
    idServicio: string;
    idServicioColectivo: string;
  }) {
    const url = `/comarcas/${comarcaId}/servicios/${idServicio}/asignarServicioProgramado`;

    return ApiService.post(url, {
      servicioProgramadoId: idServicioColectivo,
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          throw err.response.data;
        } else if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          const axiosError = { error: err };
          throw axiosError;
        }
      } else {
        const generalError = { error: err };
        throw generalError;
      }
    });
  }

  @Action({ rawError: true })
  pasarAPendiente({
    comarcaId,
    servicioId,
  }: {
    comarcaId: string;
    servicioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      servicioId,
      estado: "pendiente",
    });
  }
  @Action({ rawError: true })
  rechazarSolicitud({
    comarcaId,
    servicioId,
  }: {
    comarcaId: string;
    servicioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      servicioId,
      estado: "rechazar",
    });
  }
  @Action({ rawError: true })
  realizadoPorErrorSolicitud({
    comarcaId,
    servicioId,
  }: {
    comarcaId: string;
    servicioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      servicioId,
      estado: "realizadoPorError",
    });
  }

  @Action
  cambiarEstadoSolicitud({
    comarcaId,
    servicioId,
    estado,
  }: {
    comarcaId: string;
    servicioId: string;
    estado: string;
  }) {
    const url = `/comarcas/${comarcaId}/servicios/${servicioId}/${estado}`;

    return ApiService.post(url, {}).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  eliminar({ comarcaId, elementId }: { comarcaId: string; elementId: string }) {
    const url = `/comarcas/${comarcaId}/servicios/${elementId}`;

    return ApiService.delete(url).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  clearService() {
    this.setDetailData(null);
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.serviciosListado = data;
  }

  @Mutation
  setProfesional(data) {
    this.profesionales = data;
  }

  @Mutation
  setDetailData(data) {
    this.servicio = data;
  }
}
