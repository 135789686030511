import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface Usuario {
  id: string;
  nombre: string;
  username: string;
  email: string;
  roleId: string;
  comarcas?: { id: string; nombre: string }[];
}

@Module({ namespaced: true, name: "UsuariosListadoModule" })
export class UsuariosListadoModule extends VuexModule {
  usuarios: Usuario[] = [];
  usuario: Usuario | null = null;

  @Action({ rawError: true })
  async fetchAll() {
    const { data } = await ApiService.get("/usuarios");
    this.setListData(data);
  }

  @Action({ rawError: true })
  async fetchById(id: string) {
    const { data } = await ApiService.get(`/usuarios/${id}`, {
      params: {
        filter: {
          include: [{ relation: "comarcas" }],
        },
      },
    });
    this.setData(data);
  }

  @Action({ rawError: true })
  crear(usuario: Partial<Usuario>) {
    return ApiService.post("/usuarios", usuario).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  updateById({ id, usuario }: { id: string; usuario: Partial<Usuario> }) {
    return ApiService.put(`/usuarios/${id}`, usuario);
  }

  @Action({ rawError: true })
  deleteById(id: string) {
    return ApiService.delete(`/usuarios/${id}`);
  }

  @Mutation
  setListData(data) {
    this.usuarios = data;
  }

  @Mutation
  setData(data) {
    this.usuario = data;
  }
}
