import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  BackendError,
  BolsaCuidados,
  CreacionEmpleo,
  PlanFormacion,
  ProyectosN2Comarca,
  ProyectosN2Listado,
  ProyectosN2ListadoTabla,
} from "../models";

interface ProyectoN2Crear {
  denominacion: string;
  proyectoPadreId: string;
}

@Module({ namespaced: true, name: "ProyectosN2ListadoModule" })
export class ProyectosN2ListadoModule extends VuexModule {
  errors: BackendError | null = null;
  proyectosN2Listado: ProyectosN2Listado[] = [];
  proyectosN2ListadoTabla: ProyectosN2ListadoTabla[] = [];
  proyectoN2: ProyectosN2Listado | null = null;
  proyectoN2Crear: ProyectoN2Crear | null = null;
  bolsaCuidados: BolsaCuidados | null = null;
  creacionEmpleo: CreacionEmpleo | null = null;
  planFormacion: PlanFormacion | null = null;
  proyectosN2Comarca: ProyectosN2Comarca[] = [];

  @Action({ rawError: true })
  async fetchAll(comarcaId?: string) {
    const url =
      comarcaId == null ? "/proyectos" : `/comarcas/${comarcaId}/proyectos`;
    const include = {
      params: {
        filter: {
          include: ["comarca", "proyectoSincronizacion"],
        },
      },
    };
    //TODO filtrar datos innecesarios

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setListDataTabla(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action({ rawError: true })
  async fetchByComarca({
    comarcaId,
    comarcaIdFiltro,
  }: {
    comarcaId: string;
    comarcaIdFiltro: string;
  }) {
    this.clearProyectos();
    const url =
      comarcaId == null ? "/proyectos" : `/comarcas/${comarcaId}/proyectos`;
    const include = {
      params: {
        filter: {
          where: {
            comarcaId: comarcaIdFiltro,
          },
        },
      },
    };

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setProyectosComarca(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action({ rawError: true })
  async crear({
    proyectoN2Crear,
    comarcaId,
  }: {
    proyectoN2Crear: Partial<ProyectoN2Crear>;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null ? "/proyectos" : `/comarcas/${comarcaId}/proyectos`;

    return ApiService.post(url, proyectoN2Crear).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
        throw err;
      }
    );
  }

  @Action({ rawError: true })
  async sincronizar({
    proyectoId,
    comarcaId,
  }: {
    proyectoId: string;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null
        ? `/proyectos/${proyectoId}/sincronizar`
        : `/comarcas/${comarcaId}/proyectos/${proyectoId}/sincronizar`;

    return ApiService.post(url, null).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  async fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/proyectos/${id}`
        : `/comarcas/${comarcaId}/proyectos/${id}`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "bolsaCuidados",
              scope: {
                include: [
                  {
                    relation: "tipoBolsaCuidados",
                  },
                ],
              },
            },
            { relation: "comarca" },
            { relation: "servicios" },
            { relation: "serviciosProgramados" },
            { relation: "proyectoSincronizacion" },
            { relation: "creacionEmpleo" },
            { relation: "planFormacion" },
            { relation: "municipios" },
          ],
        },
      },
    };

    return ApiService.get(url, filter)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action({ rawError: true })
  updateById({
    id,
    proyectoN2,
    comarcaId,
  }: {
    id: string;
    proyectoN2: Partial<ProyectosN2Listado>;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null
        ? `/proyectos/${id}`
        : `/comarcas/${comarcaId}/proyectos/${id}`;
    return ApiService.put(url, proyectoN2).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  patchBolsaCuidadosById({
    id,
    bolsaCuidados,
    comarcaId,
  }: {
    id: string;
    bolsaCuidados: Partial<BolsaCuidados>;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null
        ? `/proyectos/${id}/bolsaCuidados`
        : `/comarcas/${comarcaId}/proyectos/${id}/bolsaCuidados`;

    return ApiService.put(url, bolsaCuidados).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Action({ rawError: true })
  patchEmpleoById({
    id,
    creacionEmpleo,
    comarcaId,
  }: {
    id: string;
    creacionEmpleo: Partial<CreacionEmpleo>;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null
        ? `/proyectos/${id}/creacionEmpleo`
        : `/comarcas/${comarcaId}/proyectos/${id}/creacionEmpleo`;

    return ApiService.put(url, creacionEmpleo).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Action({ rawError: true })
  patchFormacionById({
    id,
    planFormacion,
    comarcaId,
  }: {
    id: string;
    planFormacion: Partial<PlanFormacion>;
    comarcaId: string;
  }) {
    const url =
      comarcaId == null
        ? `/proyectos/${id}/planFormacion`
        : `/comarcas/${comarcaId}/proyectos/${id}/planFormacion`;

    return ApiService.put(url, planFormacion).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Action({ rawError: true })
  deleteById(id: string) {
    return ApiService.delete(`/proyectos/${id}`);
  }

  @Action({ rawError: true })
  clearProyectos() {
    this.setProyectosComarca([]);
  }

  @Mutation
  setListDataTabla(data) {
    this.proyectosN2ListadoTabla = data;
  }

  @Mutation
  setProyectosComarca(data) {
    this.proyectosN2Comarca = data;
  }

  @Mutation
  setData(data) {
    this.proyectoN2 = data;
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }
}
