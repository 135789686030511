import ApiService from "@/core/services/ApiService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { OrganismosListado } from "../models";

interface Organismo {
  id: string;
  nombre: string;
  tipoOrganizacionId: string;
  tipoOrganizacion: {
    id: string;
    nombre: string;
  };
  esUbicacionGeografica: boolean;
  telefono: string;
  direccion: string;
  email: string;
  emailProfesionales: string;
}

interface TipoOrganizacion {
  id: string;
  nombre: string;
}

interface OrganismoCrear {
  nombre: string;
  email: string;
  emailProfesionales: string;
  telefono: string;
  direccion: string;
  esUbicacionGeografica: boolean;
  tipoOrganizacionId: string;
}

@Module({ namespaced: true, name: "OrganismosModule" })
export class OrganismosModule extends VuexModule {
  organismosListado: OrganismosListado[] = [];
  organismo: OrganismosListado | null = null;
  tiposOrganizacionListado: TipoOrganizacion[] = [];
  organismoCrear: OrganismoCrear | null = null;

  @Action({ rawError: true })
  async fetchAll() {
    const url = `/comarcas`;
    const params = {
      filter: {
        include: [{ relation: "tipoOrganizacion" }],
      },
    };

    const { data } = await ApiService.get(url, { params });
    this.setListData(data);
  }

  @Action({ rawError: true })
  async fetchById(id: string) {
    const { data } = await ApiService.get(`/comarcas/${id}`, {
      params: {
        filter: {
          include: [{ relation: "tipoOrganizacion" }],
        },
      },
    });
    this.setData(data);
  }

  @Action({ rawError: true })
  async fetchTipoOrganizaciones() {
    const { data } = await ApiService.get("/tipos-organizacion");
    this.setListTipoOrganizaciones(data);
  }

  @Action({ rawError: true })
  async crear(organismoCrear: Partial<OrganismoCrear>) {
    return await ApiService.post(`/comarcas`, organismoCrear).catch(
      ApiService.handleError
    );
  }

  @Action({ rawError: true })
  updateById({ id, organismo }: { id: string; organismo: Partial<Organismo> }) {
    return ApiService.put(`/comarcas/${id}`, organismo);
  }

  @Action({ rawError: true })
  deleteById(id: string) {
    return ApiService.delete(`/comarcas/${id}`);
  }

  @Mutation
  setListData(data) {
    this.organismosListado = data;
  }

  @Mutation
  setListTipoOrganizaciones(data) {
    this.tiposOrganizacionListado = data;
  }

  @Mutation
  setData(data) {
    this.organismo = data;
  }
}
