import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AnyObject } from "yup/lib/types";
import { BackendError, Evento, FamiliaListado, NuevoMenor } from "../models";

@Module({ namespaced: true, name: "FamiliasListadoModule" })
export class FamiliasListadoModule extends VuexModule {
  errors: BackendError | null = null;
  familiasListado: FamiliaListado[] = [];
  familia: FamiliaListado | null = null;
  eventosListado: Evento[] = [];

  @Action
  fetchFamilias(comarcaId?: string) {
    const url =
      comarcaId == null ? "/familias" : `/comarcas/${comarcaId}/familias`;

    const include =
      comarcaId === null
        ? { params: { filter: { include: ["comarca"] } } }
        : {};

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/familias/${id}`
        : `/comarcas/${comarcaId}/familias/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            { relation: "comarca" },
            {
              relation: "servicios",
              scope: { include: [{ relation: "menores" }] },
            },
            { relation: "documentos" },
            { relation: "menores" },
          ],
        },
      },
    })
      .then(({ data }) => {
        if (data.servicios?.length) {
          data.servicios = data.servicios.map((servicio) => {
            return {
              ...servicio,
              familia: {
                dni: data.dni,
                nombre: data.nombre,
                apellidos: data.apellidos,
              },
            };
          });
        }
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchMenoresById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/familias/${id}`
        : `/comarcas/${comarcaId}/familias/${id}`;

    return ApiService.get(url, {
      params: {
        fields: { id: true },
        filter: { include: [{ relation: "menores" }] },
      },
    })
      .then(({ data }) => {
        this.setMenoresDetailData(data.menores);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServiciosById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/familias/${id}`
        : `/comarcas/${comarcaId}/familias/${id}`;

    return ApiService.get(url, {
      params: {
        fields: { id: true },
        filter: { include: [{ relation: "servicios" }] },
      },
    })
      .then(({ data }) => {
        this.setServiciosrogramadosDetailData(data.servicios);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchFamiliaHistorico({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/familias/${id}/registros-historicos`
        : `/comarcas/${comarcaId}/familias/${id}/registros-historicos`;

    return ApiService.get(url)
      .then(({ data }) => {
        /*if (data.servicios?.length) {
          data.servicios = data.servicios.map((servicio) => {
            return {
              ...servicio,
              familia: {
                dni: data.dni,
                nombre: data.nombre,
                apellidos: data.apellidos,
              },
            };
          });
        }*/
        this.setEventosData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  familiaHistorico({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: Partial<Evento>;
  }) {
    const url =
      comarcaId == null
        ? `/familias/${id}/registros-historicos`
        : `/comarcas/${comarcaId}/familias/${id}/registros-historicos`;

    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  create({ comarcaId, data }: { comarcaId: string; data: AnyObject }) {
    const url =
      comarcaId == null ? `/familias/` : `/comarcas/${comarcaId}/familias`;

    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  update({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/familias/${id}`
        : `/comarcas/${comarcaId}/familias/${id}`;

    return ApiService.put(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  updateComarca({
    comarcaId,
    id,
    data,
  }: {
    comarcaId: string;
    id: string;
    data: AnyObject;
  }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/familias/${id}`
        : `/comarcas/${comarcaId}/peticion-formularios/familias/${id}/reasignar`;

    data.comarcaId = data.familiaComarcaId;
    delete data.familiaComarcaId;

    return ApiService.post(url, data).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  addMenor({
    familiaId,
    comarcaId,
    menor,
  }: {
    familiaId: string;
    comarcaId: string;
    menor: NuevoMenor;
  }) {
    const url = `/comarcas/${comarcaId}/familias/${familiaId}/menores`;

    return ApiService.post(url, menor).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  addServioProgramadoMenor({
    familiaId,
    comarcaId,
    menorId,
    servicioProgramadoId,
  }: {
    familiaId: string;
    comarcaId?: string;
    menorId: string;
    servicioProgramadoId: string;
  }) {
    const url = !comarcaId
      ? `/familias/${familiaId}/servicios-programados`
      : `/comarcas/${comarcaId}/familias/${familiaId}/servicios-programados`;
    return ApiService.post(url, {
      menorId,
      servicioProgramadoId,
    }).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  eliminar({ comarcaId, elementId }: { comarcaId: string; elementId: string }) {
    const url = `/comarcas/${comarcaId}/familias/${elementId}`;

    return ApiService.delete(url).catch(ApiService.handleError);
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.familiasListado = data;
  }

  @Mutation
  setDetailData(data) {
    this.familia = data;
  }
  @Mutation
  setMenoresDetailData(data) {
    if (this.familia) this.familia.menores = data;
  }
  @Mutation
  setServiciosrogramadosDetailData(data) {
    if (this.familia) this.familia.servicios = data;
  }
  @Mutation
  setEventosData(data) {
    this.eventosListado = data;
  }
}
