import ApiService from "@/core/services/ApiService";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, FormulariosListado } from "../models";

interface SolicitudCrear {
  proyectoPadreId: string;
  denominacion: string;
  descripcion?: string | null;
  formularioPublicoId?: string | null;
  organismosIds?: string[] | null;
  cuerpoEmail: string;
}

interface SolicitudesListado {
  createdBy: string;
  modifiedBy: string;
  createdOn: Date;
  modifiedOn: Date;
  id: string;
  denominacion: string;
  descripcion: string;
  admiteRespuestas: boolean;
  formularioPublicoId: string;
  cuerpoEmail: string;
  proyectoPadreId: string;
  proyectoPadre: {
    id: string;
    codigo: string;
    denominacion: string;
  };
  formularioPublico: {
    id: string;
    nombre: string;
    fechaCreacion: Date;
  };
  entidadesSolicitudInformacionExterna: Solicitud[];
}

interface Solicitud {
  id: string;
  respondido: boolean;
  comarcaId: string;
  fechaRespuesta: Date;
  solicitudInformacionExternaId: string;
  proyectoId: string;
}

@Module({ namespaced: true, name: "FormulariosModule" })
export class FormulariosModule extends VuexModule {
  errors: BackendError | null = null;
  formulariosListado: FormulariosListado[] = [];
  formulario: FormulariosListado | null = null;
  solicitudCrear: SolicitudCrear | null = null;
  solicitudesListado: SolicitudesListado[] = [];
  solicitud: SolicitudesListado | null = null;
  organismo: Solicitud | null = null;
  organismosListado: Solicitud[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  respuestas: any[] = [];

  @Action({ rawError: true })
  async crearSolicitud({
    solicitudCrear,
  }: {
    solicitudCrear: Partial<SolicitudCrear>;
  }) {
    this.clearErrors();

    const url = "/solicitud-informacion-externa";

    return ApiService.post(url, solicitudCrear).catch(
      (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      }
    );
  }

  @Action({ rawError: true })
  async fetchAllSolicitudes() {
    this.clearErrors();
    const url = "/solicitud-informacion-externa";
    const include = {
      params: {
        filter: {
          include: ["comarca"],
        },
      },
    };
    //TODO filtrar datos innecesarios

    return ApiService.get(url, include)
      .then(({ data }) => {
        this.setListSolicitudesListado(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
        throw err;
      });
  }

  @Action({ rawError: true })
  async fetchById({ id }: { id: string }) {
    this.clearErrors();
    const url = `/solicitud-informacion-externa/${id}`;

    const filter = {
      params: {
        filter: {
          include: [{ relation: "" }],
        },
      },
    };

    return ApiService.get(url, filter)
      .then(({ data }) => {
        this.setDataSolicitud(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action({ rawError: true })
  async reenviar({ id, comarcaId }: { id: string; comarcaId: string }) {
    this.clearErrors();
    const url = `/solicitud-informacion-externa/${id}/comarca/${comarcaId}/reenviar`;

    return ApiService.post(url, null).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
      throw err;
    });
  }

  @Action({ rawError: true })
  async fetchAll() {
    this.clearErrors();
    const url = `/formulario-publico`;

    const { data } = await ApiService.get(url);
    this.setListData(data);
  }

  @Action({ rawError: true })
  async fetchRespuestas(id: string) {
    const url = `/peticion-formularios/solicitud-informacion-externa/${id}/respuestas`;

    const { data } = await ApiService.get(url);
    this.setRespuestasData(data);
    return data;
  }

  @Action({ rawError: true })
  updateById({
    id,
    solicitud,
  }: {
    id: string;
    solicitud: Partial<SolicitudesListado>;
  }) {
    this.clearErrors();
    const url = `/solicitud-informacion-externa/${id}`;
    return ApiService.put(url, solicitud).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
      throw err;
    });
  }

  @Mutation
  setListData(data) {
    this.formulariosListado = data;
  }

  @Mutation
  setData(data) {
    this.formulario = data;
  }
  @Mutation
  setRespuestasData(data) {
    this.respuestas = data;
  }

  @Mutation
  setDataSolicitud(data) {
    this.solicitud = data;
  }

  @Mutation
  setListSolicitudesListado(data) {
    this.solicitudesListado = data;
  }

  @Mutation
  clearErrors() {
    this.errors = null;
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }
}
