import { createI18n } from "vue-i18n/index";
import es from "./i18n/es.json";

const messages = { es };

const i18n = createI18n({
  legacy: false,
  locale: "es",
  fallbackLocale: "es",
  globalInjection: true,
  messages,
});

export default i18n;
